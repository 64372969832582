/* Typography */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
h1, h2, h3, h4, p {
  font-family: 'DM Sans', sans-serif;
}
h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}
h2 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
}
h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.75rem;
}
h4 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem;
}
/* Buttons */
.btn {
  display: inline-block;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary {
  color: #fff;
  background-color: #600C7E;
  border-color: #600C7E;
}
.btn-primary:hover {
  color: #fff;
  background-color: #370649;
  border-color: #370649;
}
/* Voice GUI */
.voice-gui {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
}
.voice-gui button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}
.voice-gui button:hover {
  background-color: #0069d9;
}
.voice-gui input {
  flex-grow: 1;
  font-family: 'DM Sans', sans-serif;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}